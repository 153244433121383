/*eslint-disable */
import {bus} from '@/main';  
import toast from '@/services/toast';
export default {
  name: 'ngo-join-request-popup',
  components: {},
  props: ['joinID'],
  data () {
    return {
      selfUserId:''
    }
  },
  computed: {

  },
  mounted () {
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
    }
  },
  methods: {
    closeJoinRecord() {
      bus.$emit('joinRequestRecordModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    confirmJoinRecord(){
      let data = {
        active:false,
        user:this.selfUserId,
        ngo_form:this.joinID
      }
      this.$store.dispatch('updateNgoMemberFunc', data).then((res) => {
        toast.success(res.data.msg);
        this.getngoListFun();
        this.closeJoinRecord();
      }).catch((err) => {
        toast.error(err.data.user_msg || err.data.msg);
      })
    },
    getngoListFun() {
      this.$store.dispatch('getNGOInfoNByIdApiFun', {id:this.joinID})
    },
  }
}


